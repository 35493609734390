:root {
  --color_red: #f92c2c;
  --color_yellow: #ffc609;
  --color_green: #32cd32;
  --color_black: #070b28;
  --color_grey: #b4b6c4;
  --color_silver: #d3d3d3;
  --color_white: #ffffff;
  --color_blue: #1b5ca7;
  --color_orange: #f44336;
  --color_sky_blue: #43f2ff;
  --color_light_green: #09ffc4;
  --color_light_silver: #e8e9eb;
  --color_border_color: #707070;
  --color_dark_green: #228b22;
  --color_light_orange: #ff8c00;
  --color_dark_red: #ff0000;
  --color_purple: rgb(225, 10, 225);

  /* // Common Colors */
  --color_color_input_text: #70717a;
  --color_color_input_text_border: #d5d7e3;
}

html div {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

html button:hover {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

/* Daily Login Rewards Starts */
.dlr_main_div {
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.dlr_header {
  height: 65px;
  min-height: 60px;
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
}

.dlr_header_text {
  font-size: 26px;
  font-weight: 1000;
  color: var(--color_white);
  text-transform: uppercase;
}

.dlr_body {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 25px;
  background-color: var(--color_white);
  border-radius: 50px 50px 0 0;
  overflow: hidden;
}

.dlr_body_scrollable_component {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: auto;
}

.dlr_body_scrollable_component::-webkit-scrollbar {
  display: none;
}

.dlr_reward_row {
  width: 95%;
  height: 50px;
  display: flex;
  border: 1px solid var(--color_grey);
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgb(7 11 40 / 10%);
  margin: 5px 0;
}

.dlr_day_col {
  width: 20%;
  background-color: var(--color_light_green);
  border-radius: 10px 0 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

.dlr_reward_col {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 800;
}

.dlr_reward_type_col {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  padding: 10px 0;
}

.dlr_claimed_col {
  width: 30%;
  border-radius: 0 10px 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  padding: 10px 0;
}

.dlr_avatar_col {
  width: 20px;
}

.dlr_reward_type,
.dlr_claimed {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid var(--color_grey);
}

/* Daily Login Rewards Ends */

/* Join Via Invite Code Starts */
.jvic_main_div {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 11;
  background-color: rgba(0, 0, 0, 0.5);
}

.jvic_popup {
  width: 100%;
  /* height: 50%; */
  /* background-color: var(--color_yellow); */
  display: flex;
  flex-direction: column;
  bottom: 0;
  position: absolute;
  border-radius: 35px 35px 0 0;
}

.jvic_header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: var(--color_yellow);
  border-radius: 35px 35px 0 0;
}

.jvic_header_text {
  font-size: 20px;
  font-weight: 600;
  margin-left: 35px;
}

.jvic_cancel_icon {
  width: 30px;
  cursor: pointer;
}

.jvic_body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: var(--color_white);
}

.jvic_body_scrollable_component {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-around; */
  padding: 25px;
  background-color: var(--color_white);
  overflow: auto;
  margin-bottom: 60px;
  padding-bottom: 60px;
}

.jvic_body_scrollable_component::-webkit-scrollbar {
  display: none;
}

.jvic_text {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  font-size: 18px;
  padding: 15px 15px 15px 0;
}

.jvic_input {
  width: 100%;
  display: flex;
  font-size: 18px;
  padding: 10px 15px 10px 20px;
  border: 1px solid var(--color_black);
  border-radius: 50px;
  outline: none;
}

.jvic_button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  padding: 10px 15px 10px 20px;
  border: none;
  border-radius: 50px;
  background-color: var(--color_black);
  color: var(--color_white);
  outline: none;
  margin-top: 25px;
  text-transform: uppercase;
}

.jvic_button:hover {
  background-color: var(--color_red);
}

/* Join Via Invite Code Ends */

/* Refer A Friend Starts */
.raf_main_div {
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--color_yellow);
}

.raf_header {
  height: 65px;
  min-height: 60px;
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
}

.raf_header_text {
  font-size: 26px;
  font-weight: 1000;
  color: var(--color_black);
  text-transform: uppercase;
}

.raf_body {
  height: 100%;
  display: flex;
  flex-direction: column;
  /* padding: 25px; */
  background-color: var(--color_black);
  /* border-radius: 50px 50px 0 0; */
  overflow: hidden;
}

.raf_body_scrollable_component {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: auto;
  background-color: var(--color_yellow);
}

.raf_body_scrollable_component::-webkit-scrollbar {
  display: none;
}

.raf_freinds_img {
  height: 300px;
}

.raf_invite_and_earn_div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* overflow: hidden; */
  background-color: var(--color_black);
  border-radius: 50px 50px 0 0;
  padding: 0 25px 40px 25px;
}

.raf_body_title {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  color: var(--color_white);
  font-size: 18px;
  font-weight: 600;
}

.raf_earn_numbers {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  color: var(--color_white);
  font-size: 22px;
  font-weight: 800;
}

.raf_img_text_row {
  width: 100%;
  /* height: 170px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.raf_img_text_col {
  width: 49%;
  /* padding: 10px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.raf_img_round_component {
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--color_yellow);
}

/* .raf_img_round {
  width: 80px;
} */

.raf_normal_text {
  width: 100%;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 10px;
  color: var(--color_white);
  height: 70px;
}

.raf_refer_invite_amout_img {
  width: 14px;
}

.raf_referral_code_text {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--color_white);
  font-size: 16px;
  font-weight: 600;

  padding-left: 10px;
}

.raf_referral_code_component {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 5px 0 0 10px!important;
}

.raf_referral_code {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px dashed var(--color_yellow);
  border-radius: 5px;
  background-color: var(--color_color_input_text);
  color: var(--color_white);
  padding: 10px;
  font-size: 17px;
  font-weight: 600;
}

.raf_copy_button {
  /* width: 20%; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-size: 18px;
  font-weight: 600;
  color: var(--color_white);
  border-radius: 50px;
  border: 2px solid var(--color_white);
  padding: 10px 25px;
  cursor: pointer;
}

/* Refer A Friend Ends */

/* How To Play Starts */
.htp_main_div {
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.htp_header {
  height: 65px;
  min-height: 60px;
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
}

.htp_header_text {
  font-size: 26px;
  font-weight: 1000;
  text-transform: uppercase;
  color: var(--color_white);
}

.htp_body {
  height: 100%;
  display: flex;
  flex-direction: column;
  /* padding: 25px; */
  background-color: var(--color_white);
  border-radius: 50px 50px 0 0;
  overflow: hidden;
}

.htp_body_scrollable_component {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: auto;
  padding: 20px;
}

.htp_body_scrollable_component::-webkit-scrollbar {
  display: none;
}

.htp_language_tabs_row {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background-color: var(--color_red);
  color: var(--color_white);
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
}

.htp_language_tab {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 0;
  cursor: pointer;
}

/* .htp_language_tab:hover {
  background-color: var(--color_black);
  border-radius: 50px;
} */

.htp_language_tab_active {
  background-color: var(--color_black);
  border-radius: 50px;
}

.htp_steps_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  border-radius: 10px;
  border: 1px solid var(--color_color_input_text_border);
}

.htp_steps_box_row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  cursor: pointer;
}

.htp_steps_box_row:hover {
  background-color: var(--color_light_silver);
}

.htp_steps_title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  color: var(--color_border_color);
}

.htp_step_details_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  transition: all 1s;
}

.htp_content_type_desc {
  width: 100%;
  text-align: left;
  font-size: 18px;
  color: var(--color_border_color);
}

.htp_content_type_image {
  width: 300px;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}

/* How To Play Ends */

/*  */
.cyfg_main_div {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 11;
  background-color: rgba(0, 0, 0, 0.5);
}

.cyfg_popup {
  width: 100%;
  height: 80%;
  /* background-color: var(--color_yellow); */
  display: flex;
  flex-direction: column;
  bottom: 0;
  position: absolute;
  border-radius: 35px 35px 0 0;
}

.cyfg_header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 0 20px;
  background-color: var(--color_white);
  border-radius: 35px 35px 0 0;
}

.cyfg_header_text {
  font-size: 20px;
  font-weight: 600;
  margin-left: 35px;
}

.cyfg_cancel_icon {
  width: 30px;
  cursor: pointer;
}

.cyfg_body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-around; */
  background-color: var(--color_white);
}

.cyfg_body_scrollable_component {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-start-end-radius: ;
  /* justify-content: space-around; */
  padding: 0 25px 25px 25px;
  background-color: var(--color_white);
  overflow: auto;
  margin-bottom: 60px;
  padding-bottom: 15px;
}

.cyfg_body_scrollable_component::-webkit-scrollbar {
  display: none;
}

.cyfg_text_normal {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  font-size: 20px;
  /* padding: 15px 15px 15px 0; */
  color: var(--color_black);
}

.cyfg_text_big {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  font-size: 26px;
  font-weight: 600;
  /* padding: 15px 15px 15px 0; */
  color: var(--color_red);
  text-transform: uppercase;
}

.cyfg_scrollable_game_list_component {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 10px 0;
}

.cyfg_scrollable_game_list_component::-webkit-scrollbar {
  display: none;
}

.cyfg_game_component_col {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.cyfg_game_component {
  min-width: 150px;
  width: 100%;
  min-height: 150px;
  /* margin: 7px; */
  /* margin-right: 7px; */
  display: flex;
  flex-direction: column;
  background-color: var(--woe_color_white);
  /* border: 3px solid var(--color_black); */
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

.cyfg_game_component:hover {
  opacity: 0.5;
}

.cyfg_game_img {
  width: 100%;
  height: 100%;
  min-height: 150px;
  object-fit: cover;
  display: flex;
  position: relative;
  top: 0;
  bottom: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* background-attachment: fixed; */
  z-index: 0;
}

.cyfg_game_gradient {
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  position: absolute;
  mix-blend-mode: multiply;
  background-image: linear-gradient(
    to bottom,
    rgba(82, 82, 82, 0) 70%,
    #525252 100%
  );
}

.cyfg_game_name {
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--woe_color_white);
  position: absolute;
  /* top: -20px; */
  bottom: 0;
  z-index: 2;
  /* padding: 0 0 15px 0; */
  overflow: hidden;
  white-space: nowrap;
}

.cyfg_game_selected_icon {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  justify-content: flex-end;
  padding: 5px;
}

.cyfg_game_selected_icon .fa-check-circle:before {
  background: var(--color_red);
  border: 1px solid var(--color_red);
  border-radius: 50%;
}

.cyfg_button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  padding: 10px 15px 10px 20px;
  border: none;
  border-radius: 50px;
  background-color: var(--color_red);
  color: var(--color_white);
  outline: none;
  margin-top: 25px;
  text-transform: uppercase;
}

.cyfg_button:hover {
  background-color: var(--color_dark_red);
}

/* My Videos Starts */
.myvd_main_div {
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.myvd_header {
  height: 65px;
  min-height: 60px;
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
}

.myvd_header_text {
  font-size: 26px;
  font-weight: 1000;
  color: var(--color_white);
  text-transform: uppercase;
}

.myvd_body {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 25px;
  background-color: var(--color_white);
  border-radius: 50px 50px 0 0;
  overflow: hidden;
}

.myvd_body_scrollable_component {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: auto;
}

.myvd_body_scrollable_component::-webkit-scrollbar {
  display: none;
}

.myvd_form_component {
  width: 100%;
}

.myvd_form_text {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  font-size: 18px;
  padding: 15px 15px 15px 0;
}

.myvd_form_input {
  width: 100%;
  display: flex;
  font-size: 18px;
  padding: 10px 15px 10px 20px;
  border: 1px solid var(--color_black);
  border-radius: 50px;
  outline: none;
}

.myvd_form_button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  padding: 10px 15px 10px 20px;
  border: none;
  border-radius: 50px;
  background-color: var(--color_black);
  color: var(--color_white);
  outline: none;
  margin-top: 25px;
  text-transform: uppercase;
}

/* .myvd_form_button:hover:not(disabled) {
  background-color: var(--color_yellow)
} */

.myvd_form_channel_details {
  width: 100%;
  border-radius: 25px;
  border: 1px solid var(--color_black);
  background-color: var(--color_yellow);
  color: var(--color_black);
  margin-top: 25px;
  padding: 15px;
}

.myvd_form_channel_details_title {
  font-size: 18px;
  font-weight: 600;
}

.myvd_form_channel_details_data {
  font-size: 18px;
}

.myvd_channel_status_section {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  padding: 10px;
  border-radius: 15px;
  background-color: var(--color_black);
}

.myvd_channel_status_channel_img {
  width: 75px;
  min-width: 75px;
  max-width: 75px;
  height: 75px;
  min-height: 75px;
  max-height: 75px;
  border-radius: 50%;
  border: none;
}

.myvd_channel_status_name_subs {
  display: flex;
  flex-direction: column;
  color: white;
}

.myvd_channel_status_name {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 22px;
  font-weight: 800;
}

.myvd_channel_status_subs {
  font-size: 18px;
  color: var(--color_grey);
}

.myvd_channel_status {
  background-color: white;
  padding: 5px 10px;
  margin-left: auto;
  margin-bottom: auto;
  font-size: 20px;
  border-radius: 0 15px;
}

.myvd_channel_status_img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.myvd_channel_status_reason {
  width: 100%;
  background: white;
  padding: 5px;
  font-size: 16px;
  border-radius: 5px;
  margin-top: 10px;
}

/* My Videos Ends */
