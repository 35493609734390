:root {
  --color_red: #F92C2C;
  --color_yellow: #FFC609;
  --color_green: #32CD32;
  --color_black: #070B28;
  --color_grey: #B4B6C4;
  --color_silver: #D3D3D3;
  --color_white: #FFFFFF;
  --color_blue: #1B5CA7;
  --color_orange: #F44336;
  --color_sky_blue: #43F2FF;
  --color_light_green: #09FFC4;
  --color_light_silver: #E8E9EB;
  --color_border_color: #707070;
  --color_dark_green: #228B22;
  --color_light_orange: #FF8C00;
  --color_dark_red: #FF0000;
  --color_purple: rgb(225, 10, 225);

  /* // Common Colors */
  --color_color_input_text: #70717A;
  --color_color_input_text_border: #D5D7E3;
}

html div {
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -ms-transition: all .5s;
  transition: all .5s;
}

html button:hover {
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -ms-transition: all .5s;
  transition: all .5s;
}

.pg_main_div {
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  display: flex;
  flex-direction: column; 
}

.pg_header {
  height: 65px;
  min-height: 60px;
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
}

.pg_header_text {
  font-size: 26px;
  font-weight: 1000;
  color: var(--color_white);
  text-transform: uppercase;
}
.pg_body_app {
  height: 100%;
  display: flex;
  flex-direction: column;
  /* padding: 25px; */
  background-color: var(--color_white);
  /* border-radius: 50px 50px 0 0; */
  overflow: hidden;
}
.pg_body {
  height: 100%;
  display: flex;
  flex-direction: column;
  /* padding: 25px; */
  background-color: var(--color_white);
  border-radius: 50px 50px 0 0;
  overflow: hidden;
}

.pg_body_scrollable_component {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: auto;
}

.pg_body_scrollable_component::-webkit-scrollbar {
  display: none;
}

.pg_account_details {
  width: 90%;
  /* height: 45px; */
  /* min-height: 40px; */
  display: flex;
  padding: 20px;
  margin: 20px 0;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  border-radius: 50px;
  background-color: var(--color_red);
  color: white;
  font-size: 18px;
  font-weight: 800;
}

.pg_section {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.pg_section_header {
  width: 100%;
  display: flex;
  padding: 20px;
  justify-content: flex-start;
  align-items: center;
  align-self: center;
  background-color: var(--color_light_silver);
  color: black;
  font-size: 18px;
  font-weight: 800;
  text-transform: uppercase;
}

.pg_section_body {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  background-color: var(--color_white);
}

.pg_section_details_row {
  width: 100%;
  display: flex;
  padding: 20px;
  justify-content: space-between;
  /* align-items: center; */
  align-self: center;
  color: var(--color_grey);
  font-size: 16px;
  font-weight: 600;
}

.pg_section_details_col {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pg_link{
  cursor: pointer;
}
/* .pg_section_details_img {
  
} */

.pg_section_horizontal_divider {
  width: 90%;
  height: 1px;
  background-color: var(--color_light_silver);
}

.pg_section_more_less_details_row {
  width: 100%;
  display: flex;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  border: 1px solid transparent;
  color: var(--color_black);
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
}

/* .pg_section_more_less_details_row:hover {
  opacity: 0.7;
  border: 1px solid var(--color_silver);
  border-radius: 50px;
  box-shadow: 0 3px 10px rgba(0 0 0 / 0.2);
  transition: border 0s;
} */

.pg_section_plus_icon_component {
  display: flex;
}

.pg_section_plus_icon {
  color: var(--color_black);
  padding: 5px 15px;
  margin: auto 20px auto 0;
  border: 1px solid var(--color_black);
  border-radius: 4px;
}

.pg_section_card_details {
  display: flex;
  flex-direction: column;
}

.pg_section_right_arrow_icon_component {
  margin-left: auto;
}

/* Card Popup Starts */
.pg_card_popup_main_div {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 11;
  background-color: rgba(0, 0, 0, 0.5);
}

.pg_card_popup_popup {
  width: 100%;
  /* height: 50%; */
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  bottom: 0;
  position: absolute;
  border-radius: 35px 35px 0 0;
}

.pg_card_popup_header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  /* background-color: var(--color_yellow); */
  border-radius: 35px 35px 0 0;
}

.pg_card_popup_header_text {
  font-size: 20px;
  font-weight: 600;
  margin-left: 35px;
}

.pg_card_popup_cancel_icon {
  width: 30px;
  cursor: pointer;
}

.pg_card_popup_body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: var(--color_white);
}

.pg_card_popup_body_scrollable_component {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-around; */
  padding: 25px;
  background-color: var(--color_white);
  overflow: auto;
  margin-bottom: 60px;
  /* padding-bottom: 60px; */
}

.pg_card_popup_body_scrollable_component::-webkit-scrollbar {
  display: none;
}

.pg_card_popup_form_input_component {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 5px 0;
}

.pg_card_popup_text {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  font-size: 18px;
  padding: 5px 15px 5px 0;
}

.pg_card_popup_input_component {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 15px 10px 20px;
  align-items: center;
  border: 1px solid #D3D3D3;
  border-radius: 50px;
  position: relative;
  /* margin-bottom: 20px; */
}

.pg_card_popup_input {
  width: 100%;
  display: flex;
  font-size: 18px;
  /* padding: 10px 15px 10px 20px; */
  outline: none;
  border: none;
  outline: none;
}

.pg_card_popup_icon {
  position: absolute;
  right: 15px;
  pointer-events: none;
}

.pg_card_popup_error_div {
  width: 100%;
  height: 20px;
  font-size: 16px;
  color: var(--secondary);
  margin-bottom: 5px;
  /* display: none; */
}

.pg_card_popup_button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  padding: 10px 15px 10px 20px;
  border: none;
  border-radius: 50px;
  background-color: var(--color_black);
  color: var(--color_white);
  outline: none;
  margin-top: 25px;
  text-transform: uppercase;
}

.pg_card_popup_button:hover {
  background-color: var(--color_red)
}
/* Card Popup Ends */

/* Starts */
.pg_nb_popup_main_div {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 11;
  background-color: rgba(0, 0, 0, 0.5);
}

.pg_nb_popup_popup {
  width: 100%;
  height: 90%;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  bottom: 0;
  position: absolute;
  border-radius: 35px 35px 0 0;
}

.pg_nb_popup_header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  /* background-color: var(--color_yellow); */
  border-radius: 35px 35px 0 0;
}

.pg_nb_popup_header_text {
  font-size: 20px;
  font-weight: 600;
  margin-left: 35px;
}

.pg_nb_popup_cancel_icon {
  width: 30px;
  cursor: pointer;
}

.pg_nb_popup_body {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: var(--color_white);
}

.pg_nb_popup_body_scrollable_component {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-around; */
  padding: 25px;
  background-color: var(--color_white);
  overflow: hidden;
  /* margin-bottom: 60px; */
  /* padding-bottom: 60px; */
}

/* .pg_nb_popup_body_scrollable_component::-webkit-scrollbar {
  display: none;
} */

.pg_nb_popup_search_component {
  width: 100%;
  padding: 20px;
  padding-top: 0;
}

.select__control {
  border-radius: 20px !important;
  height: 50px;
}

.select__control:hover {
  border-color: #070B28;
  border-radius: 20px;
}

.select__control--is-focused {
  border-color: #070B28;
  box-shadow: 0 0;
}

.select__menu {
  z-index: 10;
  min-width: 175px;
}

.pg_nb_popup_bank_list_component {
  width: 90%;
  height: 100%;
  overflow: auto;
  padding: 10px;
  border: 1px solid var(--color_silver);
  border-radius: 20px;
}

/* .pg_nb_popup_bank_list_component::-webkit-scrollbar {
  width: 10px;
}

.pg_nb_popup_bank_list_component::-webkit-scrollbar-thumb {
  color: var(--color_black);
} */

.pg_nb_popup_bank_details {
  width: 100%;
  padding: 15px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--color_light_silver);
  cursor: pointer;
}

.pg_nb_popup_bank_details:hover {
  opacity: 0.7;
  background-color: var(--color_light_silver);
  border-radius: 50px;
}

/* Ends */
