:root {
  --color_red: #F92C2C;
  --color_yellow: #FFC609;
  --color_green: #32CD32;
  --color_black: #070B28;
  --color_grey: #B4B6C4;
  --color_silver: #D3D3D3;
  --color_white: #FFFFFF;
  --color_blue: #1B5CA7;
  --color_orange: #F44336;
  --color_sky_blue: #43F2FF;
  --color_light_green: #09FFC4;
  --color_light_silver: #E8E9EB;
  --color_border_color: #707070;
  --color_dark_green: #228B22;
  --color_light_orange: #FF8C00;
  --color_dark_red: #FF0000;
  --color_purple: rgb(225, 10, 225);

  /* // Common Colors */
  --color_color_input_text: #70717A;
  --color_color_input_text_border: #D5D7E3;
}

html div {
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -ms-transition: all .5s;
  transition: all .5s;
}

html button:hover {
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -ms-transition: all .5s;
  transition: all .5s;
}

.vme_main_div {
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column; 
}

.vme_header {
  height: 65px;
  min-height: 60px;
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
}

.vme_header_text {
  font-size: 26px;
  font-weight: 1000;
  color: var(--color_white);
  text-transform: uppercase;
}

.vme_body {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 25px;
  background-color: var(--color_white);
  border-radius: 50px 50px 0 0;
  overflow: hidden;
}

.vme_body_scrollable_component {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: auto;
}

.vme_body_scrollable_component::-webkit-scrollbar {
  display: none;
}

.vme_account_details_component {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  margin-bottom: 20px;
  border: 1px solid var(--color_color_input_text_border);
  border-radius: 50px;
  box-shadow: 0 3px 6px 0 rgb(7 11 40 / 10%);
  cursor: pointer;
}

.vme_account_details_component:hover {
  background-color: var(--color_light_silver);
}

.vme_account_details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.vme_account_details_title {
  font-size: 18px;
  font-weight: 600;
}

.vme_image_component {
  width: 50px;
  display: flex;
}

.vme_image {
  height: 35px;
  margin: auto 10px;
}

.vme_account_details_data {
  font-size: 14px;
  color: var(--color_green);
}

.vme_or {
  margin: 20px 0;
  font-size: 14px;
  color: var(--color_grey);
}

.vme_input_component {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20px;
}

.vme_input_label {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 18px;
}

.vme_input {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 20px;
  /* margin-bottom: 20px; */
  border: 1px solid var(--color_color_input_text_border);
  border-radius: 50px;
  font-size: 18px;
}

.vme_input_note {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 10px;
  color: var(--color_color_input_text);
  font-size: 18px;
}

.vme_submit_button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  padding: 15px 20px;
  /* margin-top: auto; */
  border-radius: 50px;
  color: var(--color_white);
  background-color: var(--color_black);
  font-size: 18px;
  font-weight: 600;
}

/* OTP Popup Starts */
.vme_otp_popup_main_div {
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column; 
}

.vme_otp_popup_header {
  height: 65px;
  min-height: 60px;
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
}

.vme_otp_popup_header_text {
  font-size: 26px;
  font-weight: 1000;
  color: var(--color_white);
  text-transform: uppercase;
}

.vme_otp_popup_body {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 25px;
  background-color: var(--color_white);
  border-radius: 50px 50px 0 0;
  overflow: hidden;
}

.vme_otp_popup_body_scrollable_component {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: auto;
}

.vme_otp_popup_body_scrollable_component::-webkit-scrollbar {
  display: none;
}

.vme_otp_title {
  margin: 25px 0;
  font-size: 34px;
  font-weight: 800;
}

.vme_otp_text_component {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 20px;
}

.vme_otp_text {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  font-size: 18px;
}

.vme_otp_text_bold {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  font-size: 18px;
  font-weight: 600;
}

.otp_input_single_box {
  box-shadow: none;
  background-color: var(--white);
  border: 2px solid #D5D7E3;
  color: #70717A;
  height: 60px;
  font-family: 'Proxima Nova';
  font-size: 21px;
  border-radius: 50px;
  transition: all 0.5s ease;
  padding: 0 20px;
  font-weight: 600;
  margin: 0 auto 20px auto;
  display: flex;
  text-align: center;
}

/* OTP Popup Ends */
