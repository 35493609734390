:root {
  --color_red: #F92C2C;
  --color_yellow: #FFC609;
  --color_green: #32CD32;
  --color_black: #070B28;
  --color_grey: #B4B6C4;
  --color_silver: #D3D3D3;
  --color_white: #FFFFFF;
  --color_blue: #1B5CA7;
  --color_orange: #F44336;
  --color_sky_blue: #43F2FF;
  --color_light_green: #09FFC4;
  --color_light_silver: #E8E9EB;
  --color_border_color: #707070;
  --color_dark_green: #228B22;
  --color_light_orange: #FF8C00;
  --color_dark_red: #FF0000;
  --color_purple: rgb(225, 10, 225);

  /* // Common Colors */
  --color_color_input_text: #70717A;
  --color_color_input_text_border: #D5D7E3;
}

/* Free Games Categories Starts */
.fg_category_main_div {
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  display: flex;
  flex-direction: column; 
}

.fg_category_header {
  height: 60px;
  min-height: 60px;
  max-height: 60px;
  display: flex;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
}

.fg_category_back_arrow {
  width: 33px;
}

html[dir="rtl"] .fg_category_back_arrow{
  transform: rotate(180deg);
}
.fg_category_header_text {
  font-size: 26px;
  font-weight: 1000;
  color: var(--color_white);
  /* text-transform: uppercase; */
}

.fg_category_body {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 10px;
  background-color: var(--color_white);
  border-radius: 50px 50px 0 0;
  overflow: hidden;
}

.fg_category_body_scrollable_component {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: auto;
}

.fg_category_body_scrollable_component::-webkit-scrollbar {
  display: none;
}

.fg_category_box {
  width: 100%;
  height: 220px;
  padding: 0px 5px 7px 5px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: var(--color_black);
  cursor: pointer;
}

.fg_category_box:hover {
  opacity: 0.5;
}

.fg_category_title {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  margin: 3px 0 5px;
  font-weight: 900;
  color: var(--color_white);
}

.fg_category_image_joined_players {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.fg_category_image {
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 8px;
  z-index: 0;
}

.fg_category_joined_players {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;
  color: var(--color_white);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

.fg_GameIconSmall1_image {
  width: 16px;
}

.fg_category_joined_players_numbers {
  font-size: 16px;
  font-weight: 600;
  margin: 0 5px;
}

.fg_category_image_joined_players_gradient {
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  position: absolute;
  mix-blend-mode: multiply;
  background-image: linear-gradient(to bottom, rgba(82, 82, 82, 0) 70%, #525252 100%);
}
/* Free Games Categories Ends */

/* Free Games Type Starts */
.fg_type_main_div {
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  display: flex;
  flex-direction: column; 
}

.fg_type_header {
  height: 60px;
  min-height: 60px;
  max-height: 60px;
  display: flex;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
}

.fg_type_back_arrow {
  width: 33px;
}

html[dir="rtl"] .fg_type_back_arrow{
  transform: rotate(180deg);
}

.fg_type_header_text {
  font-size: 26px;
  font-weight: 1000;
  color: var(--color_white);
  /* text-transform: uppercase; */
}

.fg_type_body {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 10px;
  background-color: var(--color_white);
  border-radius: 50px 50px 0 0;
  overflow: hidden;
}

.fg_type_body_scrollable_component {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: auto;
}

.fg_type_body_scrollable_component::-webkit-scrollbar {
  display: none;
}

.fg_type_box {
  width: 100%;
  height: 140px;
  padding: 0px 5px 7px 5px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: var(--color_black);
  cursor: pointer;
  overflow: hidden;
}

.fg_type_box:hover {
  opacity: 0.5;
}

.fg_type_title {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  margin: 3px 0 5px;
  font-weight: 900;
  color: var(--color_white);
  transform: none !important;
}

.fg_type_image_joined_players {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.fg_type_image {
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 8px;
  z-index: 0;
}

.fg_type_joined_players {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;
  font-size: 14px;
  color: var(--color_white);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

.fg_GameIconSmall1_image {
  width: 16px;
}

.fg_type_joined_players_numbers {
  /* font-size: 16px; */
  font-weight: 600;
  margin: 0 3px;
}

.fg_type_image_joined_players_gradient {
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  position: absolute;
  mix-blend-mode: multiply;
  background-image: linear-gradient(to bottom, rgba(82, 82, 82, 0) 70%, #525252 100%);
}
/* Free Games Type Ends */

/* Free Games Join Game Popup Starts */
.fg_join_game_popup_main_div {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 11;
  background-color: rgba(0, 0, 0, 0.5);
}

.fg_join_game_popup {
  width: 100%;
  /* height: 80%; */
  background-color: var(--color_yellow);
  display: flex;
  flex-direction: column;
  bottom: 0;
  position: absolute;
  border-radius: 35px 35px 0 0;
}

.fg_join_game_header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 0 20px;
  /* background-color: var(--color_white); */
  border-radius: 35px 35px 0 0;
}

.fg_join_game_header_text {
  font-size: 20px;
  font-weight: 600;
  margin-left: 35px;
}

.fg_join_game_cancel_icon {
  width: 30px;
  cursor: pointer;
}

.fg_join_game_body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: space-around; */
  /* background-color: var(--color_white); */
}

.fg_join_game_body_scrollable_component {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-around; */
  padding: 0 25px 25px 25px;
  /* background-color: var(--color_white); */
  overflow: auto;
  margin-bottom: 60px;
  padding-bottom: 15px;
}

.fg_join_game_body_scrollable_component::-webkit-scrollbar {
  display: none;
}

.fg_join_game_confirmation_box {
  width: 100%;
  background-color: var(--color_red);
  color: var(--color_white);
  font-size: 18px;
  font-weight: 600;
  padding: 10px;
  margin: 20px 0 10px 0;
}

.fg_join_game_wallet_component {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin: 10px 0; */
  padding: 10px 0;
  border-bottom: 1px solid var(--color_black);
}

.fg_join_game_entry_fee {
  font-size: 16px;
  font-weight: 600;
  color: var(--color_black);
}

.fg_join_game_fee {
  font-size: 16px;
  font-weight: 600;
  color: var(--color_black);
}

.fg_join_game_enjoy {
  font-size: 14px;
  /* font-weight: 600; */
  color: var(--color_black);
  margin: 15px 0;
}

.fg_join_game_button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  padding: 10px 15px 10px 20px;
  border: none;
  border-radius: 50px;
  background-color: var(--color_black);
  color: var(--color_white);
  outline: none;
  /* margin-top: 10px; */
  text-transform: uppercase;
}

.fg_join_game_button:hover {
  background-color: var(--color_red);
}
/* Free Games Join Game Popup Ends */

/* Free Games Single Game */
.fg_single_game_main_div {
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  display: flex;
  flex-direction: column; 
}

.fg_single_game_header {
  height: 60px;
  min-height: 60px;
  max-height: 60px;
  display: flex;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
}

.fg_single_game_back_arrow {
  width: 33px;
  cursor: pointer;
}

.fg_single_game_header_text {
  font-size: 26px;
  font-weight: 1000;
  color: var(--color_white);
  /* text-transform: uppercase; */
}

.fg_single_game_body {
  height: 100%;
  display: flex;
  flex-direction: column;
  /* padding: 20px 10px; */
  background-color: var(--color_black);
  /* border-radius: 50px 50px 0 0; */
  overflow: hidden;
}

.fg_single_game_body_scrollable_component {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: auto;
}

.fg_single_game_body_scrollable_component::-webkit-scrollbar {
  display: none;
}

.fg_single_game_iframe {
  width: 100%;
  height: 100%;
  border: none;
}
/* Free Games Single Game */