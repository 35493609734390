:root {
  --color_red: #F92C2C;
  --color_yellow: #FFC609;
  --color_green: #32CD32;
  --color_black: #070B28;
  --color_grey: #B4B6C4;
  --color_silver: #D3D3D3;
  --color_white: #FFFFFF;
  --color_blue: #1B5CA7;
  --color_orange: #F44336;
  --color_sky_blue: #43F2FF;
  --color_light_green: #09FFC4;
  --color_light_silver: #E8E9EB;
  --color_border_color: #707070;
  --color_dark_green: #228B22;
  --color_light_orange: #FF8C00;
  --color_dark_red: #FF0000;
  --color_purple: rgb(225, 10, 225);

  /* // Common Colors */
  --color_color_input_text: #70717A;
  --color_color_input_text_border: #D5D7E3;
}

html div {
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -ms-transition: all .5s;
  transition: all .5s;
}

html button:hover {
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -ms-transition: all .5s;
  transition: all .5s;
}

.pg_ps_main_div {
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  display: flex;
  flex-direction: column; 
}

.pg_ps_header {
  height: 65px;
  min-height: 60px;
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
}

.pg_ps_header_text {
  font-size: 26px;
  font-weight: 1000;
  color: var(--color_white);
  text-transform: uppercase;
}
.pg_ps_body_app {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 25px;
  background-color: var(--color_white);
  /* border-radius: 50px 50px 0 0; */
  overflow: hidden;
}
.pg_ps_body {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 25px;
  background-color: var(--color_white);
  border-radius: 50px 50px 0 0;
  overflow: hidden;
}

.pg_ps_body_scrollable_component {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: auto;
}

.pg_ps_body_scrollable_component::-webkit-scrollbar {
  display: none;
}

.pg_ps_account_details {
  width: 100%;
  /* height: 45px; */
  /* min-height: 40px; */
  display: flex;
  padding: 20px;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  border-radius: 50px;
  background-color: var(--color_red);
  color: white;
  font-size: 18px;
  font-weight: 800;
}

.pg_ps_status_component {
  width: 100%;
  border-radius: 26px;
}
.pg_link {
  cursor: pointer;
}