:root {
  --color_red: #F92C2C;
  --color_yellow: #FFC609;
  --color_green: #32CD32;
  --color_black: #070B28;
  --color_grey: #B4B6C4;
  --color_silver: #D3D3D3;
  --color_white: #FFFFFF;
  --color_blue: #1B5CA7;
  --color_orange: #F44336;
  --color_sky_blue: #43F2FF;
  --color_light_green: #09FFC4;
  --color_light_silver: #E8E9EB;
  --color_border_color: #707070;
  --color_dark_green: #228B22;
  --color_light_orange: #FF8C00;
  --color_dark_red: #FF0000;
  --color_purple: rgb(225, 10, 225);

  /* // Common Colors */
  --color_color_input_text: #70717A;
  --color_color_input_text_border: #D5D7E3;
}

html div {
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -ms-transition: all .5s;
  transition: all .5s;
}

html button:hover {
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -ms-transition: all .5s;
  transition: all .5s;
}

.mrt_main_div {
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.center {
  margin: auto;
  padding: 10px;
  text-align: center;
  font-size: 20px;
  font-weight: 1000;
}

.mrt_header {
  height: 65px;
  min-height: 60px;
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
}

.mrt_header_text {
  font-size: 20px;
  font-weight: 1000;
  color: var(--color_white);
  text-transform: uppercase;
}

.mrt_sub_header {
  width: 85%;
  height: 45px;
  min-height: 40px;
  display: flex;
  padding: 2px 10px;
  justify-content: space-between;
  align-items: center;
  align-self: center;
}

.mrt_tab_component {
  width: 100%;
  height: 98%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 800;
  color: var(--color_white);
  cursor: pointer;
}

.mrt_tab_component_active {
  border-bottom: 2px solid var(--color_yellow);
}

.mrt_body {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 25px;
  background-color: var(--color_white);
  border-radius: 50px 50px 0 0;
  overflow: hidden;
}

.mrt_body_scrollable_component {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: auto;
}

.mrt_body_scrollable_component::-webkit-scrollbar {
  display: none;
}

.mrt_individual_transaction_box {
  width: 100%;
  /* padding: 10px 20px; */
  margin: 5px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* box-shadow: 0 3px 6px 0 rgb(7 11 40 / 10%); */
  /* border: 1px solid var(--color_color_input_text_border); */
  /* border-radius: 50px; */
}

.mtr_collapse_box_component {
  width: 100%;
  /* padding: 10px 20px; */
  /* margin: 5px 0; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  /* border: 1px solid var(--color_black); */
  background-color: var(--color_yellow);
}

.mrt_individual_transaction_component {
  width: 100%;
  padding: 10px 20px;
  /* margin: 5px 0; */
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 6px 0 rgb(7 11 40 / 10%);
  border: 1px solid var(--color_color_input_text_border);
  border-radius: 50px;
  background-color: var(--color_white);
  cursor: pointer;
}

.mrt_individual_transaction_image {
  width: 25px;
}

.mrt_individual_transaction_text_amount {
  font-size: 18px;
  font-weight: 800;
  margin: 0 10px;
  color: var(--color_black);
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.mrt_individual_transaction_text_title {
  font-size: 16px;
  font-weight: 600;
  margin: 0 10px;
  color: var(--color_black);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* display: flex;
  align-items: center; */
  /* justify-content: center; */
}

html[dir="rtl"] .mrt_individual_transaction_gamerji_coin_image {
  width: 15px;
  margin-right: 5px;
}

.mrt_individual_transaction_gamerji_coin_image {
  width: 15px;
  margin-left: 5px;
}

.mrt_individual_transaction_arrow_component {
  margin-left: auto;
}

html[dir="rtl"] .mrt_individual_transaction_arrow_component {
  margin-right: auto !important;
  margin-left: 0;
}

.mtr_collapse_box {
  width: 100%;
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mtr_collapse_box_1_coin {
  width: 100%;
  padding: 0px 15px;
  /* margin-bottom: 10px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--color_yellow);
}

.mtr_collapse_box_1_rmg {
  width: 100%;
  padding: 0px 15px 20px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--color_yellow);
}

.mtr_collapse_box_2 {
  width: 100%;
  /* padding: 10px 15px; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -27px;
}

.mtr_collapse_box_row {
  width: 100%;
  display: flex;
  margin: 7px 0;
}

.mtr_collapse_box_col_text {
  width: 40%;
  font-size: 16px;
  font-weight: 600;
}

.mtr_collapse_box_col {
  font-size: 16px;
  font-weight: 600;
}

.mtr_collapse_box_row_button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.mtr_collapse_box_col_button {
  width: 45%;
  padding: 10px 10px;
  background-color: var(--color_white);
  border: 1px solid var(--color_black);
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  cursor: pointer;
}

.mrt_collapse_box_image {
  width: 18px;
  margin-right: 10px;
}