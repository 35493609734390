:root {
  --color_red: #F92C2C;
  --color_yellow: #FFC609;
  --color_green: #32CD32;
  --color_black: #070B28;
  --color_grey: #B4B6C4;
  --color_silver: #D3D3D3;
  --color_white: #FFFFFF;
  --color_blue: #1B5CA7;
  --color_orange: #F44336;
  --color_sky_blue: #43F2FF;
  --color_light_green: #09FFC4;
  --color_light_silver: #E8E9EB;
  --color_border_color: #707070;
  --color_dark_green: #228B22;
  --color_light_orange: #FF8C00;
  --color_dark_red: #FF0000;
  --color_purple: rgb(225, 10, 225);

  /* // Common Colors */
  --color_color_input_text: #70717A;
  --color_color_input_text_border: #D5D7E3;
}

html div {
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -ms-transition: all .5s;
  transition: all .5s;
}

html button:hover {
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -ms-transition: all .5s;
  transition: all .5s;
}

.language_popup_main_div {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 112;
  background-color: rgba(0, 0, 0, 0.5);
}

.language_popup_popup {
  width: 100%;
  height: 75%;
  background-color: var(--primary);
  display: flex;
  flex-direction: column;
  bottom: 0;
  position: absolute;
  border-radius: 35px 35px 0 0;
}

.language_popup_header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  /* background-color: var(--color_yellow); */
  border-radius: 35px 35px 0 0;
}

.language_popup_header_text {
  font-size: 24px;
  font-weight: 800;
  margin-left: 35px;
}

.language_popup_cancel_icon {
  width: 30px;
  cursor: pointer;
}

/* .language_popup_body {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: var(--primary);
}

*/ .language_popup_body {
  width: 100%;
  height: 75%;
  min-height: 75%;
  max-height: 75%;
  overflow: auto;
  margin-top: 10px;
  padding: 10px;
  display: flex;
}
.language_popup_body_scrollable_component {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-around; */
  padding: 25px;
  background-color: var(--primary);
  overflow: auto;
  /* margin-bottom: 60px; */
  /* padding-bottom: 60px; */
}

.language_popup_body_scrollable_component::-webkit-scrollbar {
  display: none;
}

.language_popup_title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 800;
  margin-bottom: 20px;
}

.language_popup_submit_button {
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  padding: 10px 15px;
  /* margin-top: auto; */
  border-radius: 50px;
  color: var(--color_white);
  background-color: var(--color_black);
  font-size: 18px;
  font-weight: 600;
  margin: 0px 10px 20px 10px;
}

.language_list_popup_body_text {
  font-size: 16px;
  padding: 10px;
  border-bottom: 1px solid #D3D3D3;
  margin: 10px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.language_popup_alphabet_box {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 2px solid white;
  background-color: var(--dark);
  color: white;
  font-size: 30px;
  font-weight: 800;
  cursor: pointer;
}

.language_popup_alphabet_box:hover {
  background-color: var(--secondary);
}

.language_popup_language_name {
  width: 100%;
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: var(--dark);
}
