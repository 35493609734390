:root {
  --woe_color_red: #f92c2c;
  --woe_color_yellow: #ffc609;
  --woe_color_green: #32cd32;
  --woe_color_black: #070b28;
  --woe_color_grey: #b4b6c4;
  --woe_color_silver: #d3d3d3;
  --woe_color_white: #ffffff;
  --woe_color_blue: #1b5ca7;
  --woe_color_orange: #f44336;
  --woe_color_sky_blue: #43f2ff;
  --woe_color_light_green: #09ffc4;
  --woe_color_light_silver: #e8e9eb;
  --woe_color_border_color: #707070;
  --woe_color_dark_green: #228b22;
  --woe_color_light_orange: #ff8c00;
  --woe_color_dark_red: #ff0000;
  --woe_color_purple: rgb(225, 10, 225);

  /* // Common Colors */
  --woe_color_color_input_text: #70717a;
  --woe_color_color_input_text_border: #d5d7e3;
}

.woe_main_col {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.woe_div_1 {
  min-height: 240px;
  max-height: 240px;
  /* border-radius: 35px 35px 0 0; */
  background-color: var(--woe_color_white);
  display: flex;
}

.woe_div_2 {
  min-height: 240px;
  max-height: 240px;
  border-radius: 35px 35px 0 0;
  background-color: var(--woe_color_white);
  display: flex;
  flex-direction: column;
  /* top: -70px; */
  position: relative;
  justify-content: center;
}

.woe_div_3 {
  /* height: 33%; */
  border-radius: 35px 35px 0 0;
  background-color: white;
  display: flex;
  /* top: -100px; */
  position: relative;
  margin-top: 10px;
}

.woe_div_2_col {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* top: -155px; */
  position: relative;
}

.woe_featured_video_text_component {
  color: black;
  font-weight: 1000;
  font-size: 18px;
  margin-bottom: 15px;
}

.woe_bg {
  border: 1px solid;
  width: 450px;
  height: 320px;
  border-radius: 25px;
  margin-top: 70px;
}

.woe_featured_video_video_component {
  width: 85%;
  min-width: 445px;
  max-width: 450px;
  height: 200px;
  border: 10px solid var(--woe_color_white);
  border-radius: 25px;
  /* top: -5rem;
    position: relative; */
  background-image: linear-gradient(
    to bottom,
    var(--woe_color_white) 60%,
    #525252 100%
  );
}

.woe_featured_video {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 10px;
  /* border: 10px solid var(--woe_color_yellow); */
}

.woe_icons {
  display: flex;
}

.view_icon {
  display: flex;
  width: 60px;
}

.view {
  font-size: 0.8rem;
  margin-top: 2px;
  margin-left: 1px;
}

.like_icon {
  display: flex;
  margin-left: 50px;
}

.like {
}

.subs {
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  height: 60px;
  margin-top: 60px;
  background-color: rgb(3, 3, 49);
  display: flex;
}
.bg_img {
  border: solid white;
  background-color: var(--woe_color_white);
  width: 45px;
  height: 45px;
  border-radius: 99px;
  margin-top: 8px;
  margin-left: 10px;
}

.logo_img {
  margin-top: 5px;
  margin-left: 5px;
  width: 30px;
  height: 30px;
  border: 0px solid white;
  border-radius: 50%;

  position: relative;
}

.subs_text {
  color: var(--woe_color_white);
  margin-left: 10px;
  font-size: 1.3rem;
  margin-top: 5px;
}

.subs_count {
  color: var(--woe_color_white);
  margin-left: 65px;
  font-size: 0.8rem;
  margin-top: 35px;
  position: absolute;
}

.line {
  border-left: 2px solid var(--woe_color_white);
  height: 45px;
  margin-left: 110px;
  margin-top: 8px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.subs_now {
  color: var(--woe_color_white);
  margin-top: 15px;
  margin-left: 20px;
  font-size: 1.3rem;
  cursor: pointer;
}

.woe_div_3_col {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* top: -4rem; */
  position: relative;
  flex-wrap: wrap;
  /* margin-left: -4px;
            margin-right: -4px; */
}

.woe_div_3_col .box {
  background: var(--primary);
  color: var(--dark);
  border-radius: 5px;
  /* padding: 4px; */
  margin-top: 12px;
  box-shadow: 0 3px 8px 0 rgba(255, 198, 9, 0.3);
}

.woe_div_3_col .items {
  /* padding-left: 4px;
                    padding-right: 4px; */
  margin-bottom: 8px;
  margin-top: 13px;
}

.woe_div_3_col .owl-theme .owl-nav {
  position: absolute;
  top: -30%;
}

.woe_div_3_col .owl-theme .owl-stage-outer .owl-stage {
  padding-left: 0 !important;
}

.woe_div_3_col .owl-prev {
  position: absolute;
  left: 350px;
}

.woe_div_3_row {
  width: 100%;
  display: flex;
  padding: 0 10px;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.view_btn {
  background-color: var(--woe_color_dark_red);
  color: white;
  border-radius: 55px;
  border-color: var(--woe_color_dark_red);
  border: solid var(--woe_color_dark_red);
  width: 100px;
  height: 28px;
  cursor: pointer;
  margin-top: 5px;
}

.arrow_icon {
  margin-left: 5px;
}

.woe_popular_videos_text_component {
  color: black;
  font-weight: 1000;
  font-size: 18px;
  margin-right: 1px;
}

.woe_veiw_all_text_component {
  color: var(--woe_color_red);
  font-weight: 1000;
  font-size: 14px;
  margin-left: auto;
  cursor: pointer;
  margin-right: 30px;
}

.woe_box_component_row {
  width: 100%;
  display: flex;
  padding: 0 5px;
  /* justify-content: center; */
  align-items: center;
  /* overflow: auto; */
  margin-bottom: 20px;
  /* margin-left: 10px; */
  /* margin-top: 20px; */
}

.woe_popular_videos_box {
  width: 200px;

  height: 150px;

  /* margin-right: 7px; */
  display: flex;
  flex-direction: column;
  padding: 7px;
  background-color: var(--woe_color_white);
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid rgb(1, 1, 46);
  box-shadow: rgb(1, 1, 51);
  border-bottom-color: white;
  border-bottom-left-radius: var(--woe_color_white);
  border-bottom-right-radius: var(--woe_color_white);
}

.woe_popular_videos {
  min-height: 77px;
  max-height: 77px;
  width: 100%;
  border: none;
  border-radius: 8px;
  margin-bottom: 2px;
}

.woe_popular_videos_name {
  max-width: 100%;
  height: 5px;
  overflow: hidden;
  font-size: 14px;
  transform: translateX(100%);
  text-align: left;
  /* animation: text_animation 30s linear infinite */
}

.pop_text {
  font-size: 0.9rem;
  width: 160px;
}

.pop_icons {
  display: flex;
}

.pop_view_icon {
  display: flex;
  width: 15px;
}

.pop_view {
  font-size: 0.7rem;
  margin-top: 5px;
}

.fav_icon {
  display: flex;
  margin-left: 50px;
  width: 15px;
  color: var(--woe_color_dark_red);
}

.fav {
  font-size: 0.7rem;
  margin-top: 5px;
}

.share_icon {
  display: flex;
  width: 15px;
  margin-left: 40px;
}

.share {
  font-size: 0.7rem;
  margin-top: 5px;
}

.pop_subs {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 40px;
  margin-top: 0px;
  background-color: rgb(3, 3, 49);
  display: flex;
  width: 200px;
}

.pop_bg_img {
  border: solid white;
  background-color: var(--woe_color_white);
  width: 30px;
  height: 30px;
  border-radius: 99px;
  margin-top: 2px;
  margin-left: 5px;
}

.pop_logo_img {
  margin-top: 0px;
  margin-left: 0px;
  width: 25px;
  height: 25px;
  border: 0px solid white;
  border-radius: 50%;

  position: relative;
}

.pop_subs_text {
  color: var(--woe_color_white);
  margin-left: 4px;
  font-size: 0.7rem;
  margin-top: 5px;
}

.pop_subs_count {
  color: var(--woe_color_white);
  margin-left: 40px;
  font-size: 0.5rem;
  margin-top: 20px;
  position: absolute;
}

.pop_line {
  border-left: 2px solid var(--woe_color_white);
  height: 30px;
  margin-left: 30px;
  margin-top: 5px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.pop_subs_now {
  color: var(--woe_color_white);
  margin-top: 12px;
  margin-left: 10px;
  font-size: 0.7rem;
  font-weight: bold;
  cursor: pointer;
  width: 50px;
}

.featured_subs {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 40px;
  margin-top: 180px;
  background-color: rgb(3, 3, 49);
  display: flex;
  width: 209px;
  position: absolute;
}

.woe_top_profiles_box {
  width:45%;
    /* max-width: 130px; */
  min-height: 100px;
  margin: auto;
  margin-bottom: 15px;
  background-color: var(--woe_color_black);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  cursor: pointer;
}

.woe_top_profiles_box_col {
  width: 100%;
  min-height: 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.woe_channels_text_div {
  display: flex;
}

.text_div {
}

.channels_pubg_text {
  font-size: 1.2rem;
  margin-top: 1px;
  font-weight: bold;
  margin-left: 10px;
}

.channels_icon_div {
  margin-top: 10px;
  margin-left: 10px;
  display: flex;
}

.channels_view_icon {
  display: flex;
}

.channels_like_icon {
  margin-left: 20px;
  display: flex;
}
.channels_views_count {
  font-size: 0.8rem;
  margin-top: 2px;
  margin-left: 5px;
  font-weight: bold;
}

.channels_like_count {
  font-size: 0.8rem;
  font-weight: bold;
  margin-top: 2px;
  margin-left: 5px;
}

.woe_main_div_channels_1_body {
  height: 100px;
  border: solid;
}

.woe_channels_video {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  width: 450px;
  height: 160px;
  margin-top: 10px;

  /* border: 10px solid var(--woe_color_yellow); */
}

.channels_subs {
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  height: 60px;

  background-color: rgb(2, 2, 36);
  display: flex;
}

.channels_logo_image {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  width: 449px;
  height: 170px;

  margin-top: 10px;
}

.channels_logo_img {
  margin-top: 10px;
  margin-left: 10px;
  width: 45px;
  height: 45px;
  border: 0px solid white;
  border-radius: 50%;

  position: relative;
}

.channels_subs_text {
  color: var(--woe_color_white);
  margin-left: 10px;
  font-size: 1.3rem;
  margin-top: 10px;
}

.channels_subs_count {
  color: var(--woe_color_white);
  margin-left: 65px;
  font-size: 0.8rem;
  margin-top: 35px;
  position: absolute;
}

.channels_line {
  border-left: 2px solid var(--woe_color_white);
  height: 45px;
  margin-left: 110px;
  margin-top: 8px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.channels_subs_now {
  color: var(--woe_color_white);
  margin-top: 15px;
  margin-left: 40px;
  font-size: 1.4rem;
  cursor: pointer;
}

.channel_img {
  border: solid white;
  background-color: var(--woe_color_white);
  width: 55px;
  height: 50px;
  border-radius: 99px;
  top: -20px;
  position: relative;
}

.channels_video_line {
  border-bottom: 1px solid var(--woe_color_grey);
  margin-top: 20px;
}

.woe_top_profiles_image {
  width: 40px;
  height: 40px;
  border: 0px solid white;
  border-radius: 50%;
  top: -5px;
  position: relative;
  margin-left: 4px;
  margin-top: 5px;
}

.pro_name {
  color: var(--woe_color_white);
}

.woe_top_profiles_player_gamerji_name,
.woe_top_profiles_player_followers {
  color: white;
  font-weight: 800;
  font-size: 12px;
}

.woe_top_profiles_text_component {
  color: black;
  font-weight: 1000;
  font-size: 18px;
  margin-right: 5px;
}

.woe_top_profiles_follow_button {
  min-width: fit-content;
  padding: 5px 10px;
  border: 1px solid;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 600;
  bottom: -18px;
  position: relative;
  color: var(--woe_color_black);
  background-color: var(--woe_color_white);
}

.woe_esports_news_text_component {
  color: black;
  font-weight: 1000;
  font-size: 18px;
  margin-right: 20px;
}

.woe_esports_news_box {
  width: 200px;
  /* min-width: 200px; */
  min-height: 150px;
  margin: auto;
  /* margin-right: 7px; */
  display: flex;
  flex-direction: column;
  padding: 7px;
  background-color: var(--woe_color_light_orange);
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  margin-right: 10px;
}

.woe_esports_news_image {
  min-height: 77px;
  max-height: 77px;
  width: 100%;
  border: none;
  border-radius: 8px;
  margin-bottom: 2px;
}

.woe_esports_news_name {
  max-width: 100%;
  height: 18px;
  overflow: hidden;
  font-size: 14px;
  /* margin: auto; */
  transform: translateX(100%);
  text-align: left;
  /* animation: text_animation 30s linear infinite */
}

.news_text {
  font-size: 0.9rem;
  width: 170px;
  /* min-height: 64px; */
  display: inline;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}

.news_icons {
  display: flex;
}

.news_view_icon {
  display: flex;
  width: 15px;
}

.news_view {
  font-size: 0.7rem;
  margin-top: 5px;
}

.news_fav_icon {
  display: flex;
  margin-left: 40px;
  width: 15px;
  color: var(--woe_color_dark_red);
}

.news_fav {
  font-size: 0.7rem;
  margin-top: 5px;
}

.news_share_icon {
  display: flex;
  width: 15px;
  margin-left: 35px;
}

.news_share {
  font-size: 0.7rem;
  margin-top: 5px;
}

/* Screen 1 Starts */
.woe_main_div_screen_1 {
  min-height: 100vh;
  z-index: 10;
}

.woe_main_div_screen_1_body {
  min-height: 100vh;
  margin-bottom: 10px;
}

.woe_screens_box_col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.featured_videos_box_screen_1 {
  width: 220px;
  height: 220px;

  /* margin: 7px; */
  /* margin-right: 7px; */
  border-bottom: none;
  border-bottom-left-radius: none;
  border-bottom-right-radius: none;
  padding: 7px;
  background-color: var(--woe_color_white);
  border: 2px solid var(--woe_color_grey);
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
}

.woe_channel_videos_box_screen_1 {
  min-width: 150px;
  width: 100%;
  min-height: 150px;

  /* margin: 7px; */
  /* margin-right: 7px; */

  padding: 7px;
  background-color: var(--woe_color_white);
  border: 2px solid var(--woe_color_grey);
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  border: none;
}

.woe_popular_videos_box_screen_1 {
  min-width: 150px;
  width: 100%;
  min-height: 150px;

  /* margin: 7px; */
  /* margin-right: 7px; */

  padding: 7px;
  background-color: var(--woe_color_white);

  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
}

.woe_popular_videos_screen_1 {
  height: 150px;
  width: 190px;

  border-radius: 8px;
}

.woe_screen_1_load_more_button_row {
  display: flex;
}

.woe_screen_1_load_more_button {
  width: fit-content;
  padding: 5px 50px;
  color: var(--woe_color_white);
  background-color: var(--woe_color_black);
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 24px;
}

.woe_individual_popular_video_component {
  width: 100%;
  height: 100vh;
  z-index: 11;
  top: 0;
  bottom: 0;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
}

.woe_individual_popular_video_col {
  width: 100%;
  height: 70%;
  display: flex;
  flex-direction: column;
  background: var(--woe_color_yellow);
  bottom: 0;
  position: absolute;
  padding: 20px 20px;
  border-radius: 35px 35px 0 0;
}

.woe_individual_popular_video_frame {
  width: 100%;
  height: 250px;
  border: none;
  border-radius: 8px;
  margin-top: 10px;
}

.woe_individual_popular_video_name {
  font-size: 18px;
  font-weight: 1000;
}

/* Screen 1 Ends */

.woe_channels_videos_box_screen_1 {
  width: 280px;
}

/* Screen 2 Starts */

.woe_main_div_screen_2 {
  min-height: 100vh;
  z-index: 10;
}

.woe_main_div_screen_2_body {
  min-height: 100vh;
  margin-bottom: 10px;
}

.woe_top_profiles_box_screen_2 {
  min-width: 190px;
  width: 100%;
  min-height: 110px;
  /* margin: 7px; */
  margin-bottom: 25px;
  background-color: var(--woe_color_black);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  cursor: pointer;
}

.woe_top_profiles_image_screen_2 {
  width: 50px;
  height: 50px;
  border: 0px solid white;
  border-radius: 50%;
  top: -25px;
  position: relative;
}

.woe_top_profiles_box_col_screen_2 {
  width: 100%;
  min-height: 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  top: -10px;
  position: relative;
}

.woe_top_profiles_follow_button_screen_2 {
  min-width: fit-content;
  padding: 5px 10px;
  border: 1px solid;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 600;
  bottom: -14px;
  position: relative;
  color: var(--woe_color_black);
  background-color: var(--woe_color_white);
}

/* Screen 2 Ends */

/* Screen 3 Starts */
.woe_main_div_screen_3 {
  min-height: 100vh;
  z-index: 10;
}

.woe_main_div_screen_3_body {
  min-height: 100vh;
  margin-bottom: 10px;
}

.woe_esports_news_box_screen_3 {
  min-width: 150px;
  width: 100%;
  min-height: 150px;
  /* margin: 7px; */
  /* margin-right: 7px; */
  display: flex;
  flex-direction: column;
  padding: 7px;
  background-color: var(--woe_color_light_orange);
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
}

.woe_esports_news_image_screen_3 {
  min-height: 111px;
  max-height: 111px;
  width: 100%;
  border: none;
  border-radius: 8px;
}

.woe_individual_esports_news {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid var(--woe_color_grey);
  border-radius: 25px;
  margin: 20px 0;
}

.woe_individual_esports_news_image {
  min-height: 150px;
  max-height: 150px;
  width: 100%;
  border: none;
  border-radius: 8px;
  object-fit: cover;
}

.woe_individual_esports_news_title {
  font-size: 16px;
  font-weight: 1000;
  margin-top: 10px;
}

.woe_individual_esports_news_published {
  font-size: 16px;
  font-weight: 1000;
  margin-top: 10px;
}

.woe_individual_esports_news_description {
  margin-top: 10px;
}

/* Screen 3 Ends */

/* Advertisement Starts */
.all_main_screens_div_related_to_ads {
  display: flex;
  flex-direction: column;
  height: 100%;
  /* 88% */
  overflow-y: auto;
  width: 100%;
  overflow-x: hidden;
}

.all_main_screens_div_related_to_ads::-webkit-scrollbar,
.all_sub_screens_div_related_to_ads::-webkit-scrollbar {
  display: none;
}

.all_sub_screens_div_related_to_ads {
  display: flex;
  flex-direction: column;
  height: 100%;
  /* 93% */
  overflow-y: auto;
  width: 100%;
  overflow-x: hidden;
}

.ad_main_component {
  position: absolute;
  bottom: 0;
  left: 0;
  /* background: #FFC609; */
  width: 100%;
  /* height: 80px; */
  display: flex;
  margin-bottom: 90px;
  align-items: center;
  justify-content: center;
  padding: 1px;
}

/* .ad_OwlCarousel {
    transform: rotate(-90deg);
} */

.ad_individual {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  color: var(--dark);
  /* transform: rotate(90deg); */
}

/* Advertisement Ends */

/* Animation Starts */
.text_animation_container {
  /* padding: 5px 10px; */
  /* min-width: 0; */
  /* width: 100px; */
  /* font-size: 2rem; */
  /* color: #708090; */
  white-space: nowrap;
  /* overflow: auto; */
  /* position: relative; */
  /* border: 1px solid #708090; */
  /* border-radius: 5px; */
}

/* 
@keyframes text_animation {
    to { transform: translateX(-100%); }
}  */
/* Animation Ends */

/* neww */

.mainSection {
  background-color: red;
  height: 420px;
  position: absolute;
  /* max-width: 470px;
    min-width: 470px; */
  width: 100%;

  z-index: 1;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  /* border-bottom-left-radius: 40px; */
}

.yellow-section {
  background-color: #ffc609;
  position: absolute;
  width: 100%;
  z-index: 1;
  border-top-left-radius: 35px;
  border-top-right-radius: 35px;
  height: 220px;
  top: 50%;
}

.white-section {
  background-color: white;
  position: absolute;
  width: 100%;
  z-index: 1;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  height: 220px;
  top: 100%;
}
.section-worldofesports {
  padding: 0 25px 20px 25px;
  z-index: 1;
}
.position {
  position: relative;
}
.margin-auto {
  margin: auto;
}
.title-section {
  display: flex;
  padding: 0 10px;

  justify-content: space-between;
}
.Title {
  text-align: center;
  font-weight: 600;
  font-size: 1.3rem;
  margin: 1rem;
}
.Card-container {
  height: 250px;
  border: 10px solid #ffc609;
  border-radius: 10px;
}
.section-links {
  align-self: center;
  color: #f92c2c;
  font-weight: 700;
  font-size: 1rem;
  margin-left: auto;
  cursor: pointer;
  text-decoration: underline;
}

html[dir="rtl"] .section-links{
  margin-right: auto;
  margin-left: 0;
}

.cards-container {
  height: 150px;
  display: flex;

  width: 100%;
  padding: 7px 7px 0 7px;
  background-color: #43f2ff;
  border-radius: 10px;
  flex-direction: column;

}

html[dir="rtl"] .cards-container{
  text-align: right;
}

.cards-container:nth-child(2) {
  background-color: #4add8a;

}

.card-section {
  display: flex;

  gap: 11px;
}

.container {
  height: 170px;
  display: flex;

  width: 100%;

  border-radius: 10px;
  flex-direction: column;
  position: relative;
}

.avtarimage {
  position: absolute;
  top: -10%;
  left: 37%;
  border-radius: 50%;
  border: 3px solid white;
}

.card3-container {
  height: 155px;
  display: flex;
background-color: #070b28;
  width: 100%;

  border-radius: 10px;
  flex-direction: column;
}

.card-text {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 55px;
  text-transform: uppercase;
  font-size: 1rem;
}
.card-subText {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  font-weight: 700;
  font-size: 1rem;
}

.card-button {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  font-weight: 700;
  font-size: 1rem;
  width: 20%;
  padding: 10px 4rem;
  background-color: white;
  color: #130e2d;
  border: 2px solid #130e2d;
  border-radius: 10px;
  position: absolute;
  bottom: -2%;
  transform: translate(-50%);
  left: calc(20% + 60px);
  cursor: pointer;
  @media only screen and (max-width: 440px) and (min-width: 300px) {
    left: calc(20% + 50px);
  }
}

.card2Container{
  height: 150px;
  display: flex;

  width: 100%;
  padding: 7px;

  border-radius: 10px;
  flex-direction: column;
  background-color: #ffc609;
  /* &:nth-child(5n + 2) {
    background-color: #43f2ff;
  }
  &:nth-child(5n + 3) {
    background-color: #e10ae1;
  }
  &:nth-child(5n + 4) {
    background-color: #09ffc4;
  }
  &:nth-child(5n + 5) {
    background-color: #ff8c00;
  } */
}

html[dir="rtl"] .card2Container{
  text-align: right;
}

.card2Container:nth-child(2) {
  background-color: #43f2ff;

}
.card2image{
  height: 85px;
  width: 100%;
}

.card2text{
  /* width: 150px; */
  -webkit-line-clamp: 1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  font-size: 1rem;
  font-weight: 300;
}
html[dir="rtl"] .card2text{
  text-align: right;
}
.card-img{
  height:85px;
  width:100%
}

.white-text{
color: white;
}