.dobns_state_div {
    width: 100%;
    height: 100vh;
    z-index: 11;
    top: 0;
    left: 0px;
    bottom: 0;
    position: absolute;
    background-color: rgba(0,0,0,.5);
}
.dobns_state_popup {
    width: 100%;
    height: 50%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    bottom: 0;
    position: absolute;
    background-color: #fff;
    border: none;
    border-radius: 70px 70px 0 0;
}
.dobns_state_body{
    width: 100% !important;
    height: 100%;
    min-height: 83px;
    max-height: 75%;
    overflow: auto;
    margin-top: -15px !important;
    padding: -10px !important;
}
.dobns_state_body_scrollable_component {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    background-color: var(--color_white);
    overflow: auto;
    margin-bottom: 60px;
    padding: 12px;
    flex-wrap: wrap;
}
.dobns_state_bodyee{
    width: 100% !important;
}